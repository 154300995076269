import React, { Component } from 'react';
import { Button, Form, Icon, Input, Modal, Radio, Spin } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import * as ticketController from '../../../../../../services/api/ticket';
import './TicketCardModal.scss';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

export class TicketCardModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ticket: {},
            isLoading: false
        }
    }

    fetchTicket() {
        const { selectedTicketId } = this.props;

        ticketController.getTicket(selectedTicketId)
            .then(res => res.json())
            .then(json => this.setState({ ticket: json }))
    }

    handleSubmit(e) {
        const { selectedTicketId, afterSubmit, form } = this.props;
        e.preventDefault();

        form.validateFields((err, values) => {
            if (err) {
                console.log(err);
            } else {
                this.setState({ isLoading: true });

                ticketController.updateTicketProcess(selectedTicketId, values)
                    .then((res) => res.json())
                    .then((json) => {
                        this.setState({ isLoading: false });
                        afterSubmit();
                    })
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { form, selectedTicketId, visible } = this.props;

        if (visible !== prevProps.visible || selectedTicketId !== prevProps.selectedTicketId) {
        	form.resetFields();
            this.fetchTicket();
        }
    }

    render() {
        const { form, ...props } = this.props;
        const { ticket, isLoading } = this.state;

        const { getFieldDecorator } = form;
        const { createdAt, user, zone, ticketProcesses } = ticket;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };

        return (
            <Modal
                className="TicketCardModal"
                width="60%"
                style={{ pointerEvents: isLoading ? 'none' : ''}}
                maskClosable={!isLoading}
                title={null}
                footer={null}
                {...props}
        	>
            	<Spin spinning={isLoading} tip="Adding new progress...">
            		<div className="TicketCard">
            			<div className="ticket-icon"><Icon type="user" /></div>
						<div className="ticket-date">{moment(createdAt).format('DD/MM')}</div>
						<div className="ticket-user">{user && user.name}</div>
						<div className="ticket-zone">{zone && zone.name}</div>
						<div className="ticket-progress-list">
							{
								_.map(ticketProcesses, (step, i) => (
									<div className="ticket-progress" key={i}>
										<div className="progress-time">{moment(step.createdAt).format('DD/MM HH:mm')}</div>
										<div className="progress-message">{step.message}</div>
									</div>
								))
							}
						</div>
            		</div>
            		<div className="form-section">
		                <p className="sub-title">Add new progress</p>
		                <Form onSubmit={(e) => this.handleSubmit(e)}>
		                    <FormItem
		                        {...formItemLayout}
		                        label="Message"
		                    >
		                        {getFieldDecorator('message', {
		                        	rules: [
		                        		{ required: true, message: 'This field is required.' }
		                        	]
		                        })(
		                            <Input maxLength={20} suffix={`${(form.getFieldValue('message') || '').length}/20`}/>
		                        )}
		                    </FormItem>
		                    <FormItem
		                        {...formItemLayout}
		                        label="Close this request?"
		                    >
		                        {getFieldDecorator('isCompleted', {
		                        	rules: [
		                        		{ required: true, message: 'This field is required.' }
		                        	],
		                        	initialValue: false
		                        })(
		                            <RadioGroup>
		                            	<Radio value={true}>Yes</Radio>
		                            	<Radio value={false}>No</Radio>
		                            </RadioGroup>
		                        )}
		                    </FormItem>
		                    <div className="footer">
		                    	<Button type="primary" size="large" onClick={(e) => this.handleSubmit(e)}>Save</Button>
		                    </div>
		                </Form>
            		</div>
                </Spin>
        	</Modal>
        );
    }
}

const WrappedTicketCardModal = Form.create()(TicketCardModal);
export default WrappedTicketCardModal;