import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Tabs, Table, Tooltip } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import * as ticketController from '../../../../services/api/ticket';
import IrreversiblePopconfirmWrapper from '../../../../components/IrreversiblePopconfirmWrapper/IrreversiblePopconfirmWrapper';
import TicketCard from './components/TicketCard/TicketCard';
import TicketCardModal from './components/TicketCardModal/TicketCardModal';
import './TicketsPage.scss';

const TabPane = Tabs.TabPane;

const REACT_APP_WS_URL = process.env.REACT_APP_WS_URL;

export class TicketsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ws: new WebSocket(REACT_APP_WS_URL),
            dataSource: [],
            assignedTickets: [],
            selectedTicketId: undefined,
            isTicketModalVisible: false
        }
    }

    getTickets() {
        const { userId } = this.props;

        ticketController.getTickets()
            .then((res) => res.json())
            .then((json) => this.setState({
                dataSource: json,
                assignedTickets: _.filter(json, (ticket) => ticket.dashboardUserId === parseInt(userId) && !ticket.isCompleted)
            }))
    }

    handleFollowUpRequestClick(ticketId) {
        ticketController.followUpTicket(ticketId)
            .then((res) => res.json())
            .then((json) => this.getTickets())
    }

    afterMessageUpdate() {
        this.setState({ isTicketModalVisible: false });
        this.getTickets();
    }

    componentDidMount() {
        const { ws } = this.state;

        ws.addEventListener('message', (event) => {
            const json = JSON.parse(event.data);
            if (json.type === 'newTicketIn')
                this.getTickets();
        });

        this.getTickets();
    }

    render() {
        const { dataSource, assignedTickets, selectedTicketId, isTicketModalVisible } = this.state;
        const columns = [{
            title: 'Request at',
            dataIndex: 'createdAt',
            align: 'center',
            render: (value, record) =>
                <div>
                    <div>{moment(value).format('DD/MM/YYYY')}</div>
                    <div>{moment(value).format('HH:mm')}</div>
                </div>
        }, {
            title: 'Zone',
            dataIndex: 'zone.name',
            align: 'center',
        }, {
            title: 'User',
            dataIndex: 'user.name',
            align: 'center',
            render: (value, record) => record.contactNumber ? `${value} (${record.contactNumber})` : value
        }, {
            title: 'Involved Ppl.',
            dataIndex: 'involvedPeople',
            align: 'center',
        }, {
            title: 'Request Type',
            dataIndex: 'requestType',
            align: 'center',
        }, {
            title: 'Latest Progress',
            dataIndex: 'ticketProcesses',
            render: (ticketProcesses, record) =>
                <div>
                    <div>{ticketProcesses[0].message}</div>
                    <div className="ticket-process-submessage">Last Updated: {moment(ticketProcesses[0].createdAt).format('DD/MM/YYYY HH:mm')}</div>
                </div>
        }];
        const unhandledColumns = _.concat(columns, {
                title: 'Follow Up',
                align: 'center',
                render: (value, record) => {
                    if (_.isNull(record.dashboardUserId))
                        return (
                            <IrreversiblePopconfirmWrapper arrowPointAtCenter={true} placement="topRight" onConfirm={() => this.handleFollowUpRequestClick(record.id)}>
                                <Tooltip arrowPointAtCenter={true} placement="bottomRight" title="Follow up this request">
                                    <Icon className="handle-btn" type="solution" />
                                </Tooltip>
                            </IrreversiblePopconfirmWrapper>
                        );
                }
            }),
            handledColumns = _.concat(columns, {
                title: 'Handled By',
                dataIndex: 'dashboardUser.name',
                align: 'center',
            });

        return (
            <div className="TicketsPage">
                <div className="page-header">
                    <h2>Tickets in Progress</h2>
                </div>
                <div className="ticket-card-list">
                    {
                        assignedTickets.length !== 0 ?
                        _.map(assignedTickets, (ticket, i) => (
                            <TicketCard 
                                key={i} 
                                ticket={ticket} 
                                onUpdateClick={(e) => this.setState({ selectedTicketId: ticket.id, isTicketModalVisible: true })}
                            />
                        )) :
                        <p className="instruction">No tickets yet.</p>
                    }
                </div>
                <div className="ticket-table-list">
                    <Tabs defaultActiveKey="Unhandled">
                        <TabPane tab="Unhandled" key="Unhandled">
                            <Table
                                size="middle"
                                columns={unhandledColumns}
                                dataSource={_.filter(dataSource, record => _.isNull(record.dashboardUserId))}
                                rowKey="id"
                                pagination={{
                                    pageSize: 5
                                }}
                            />
                        </TabPane>
                        <TabPane tab="Processing" key="Processing">
                            <Table
                                size="middle"
                                columns={handledColumns}
                                dataSource={_.filter(dataSource, record => !_.isNull(record.dashboardUserId) && !record.isCompleted)}
                                rowKey="id"
                                pagination={{
                                    pageSize: 5
                                }}
                            />
                        </TabPane>
                        <TabPane tab="Completed" key="Completed">
                            <Table
                                size="middle"
                                columns={handledColumns}
                                dataSource={_.filter(dataSource, record => record.isCompleted)}
                                rowKey="id"
                                pagination={{
                                    pageSize: 5
                                }}
                            />
                        </TabPane>
                    </Tabs>
                </div>
                <TicketCardModal
                    selectedTicketId={selectedTicketId}
                    visible={isTicketModalVisible}
                    onCancel={(e) => this.setState({ isTicketModalVisible: false })}
                    afterSubmit={() => this.afterMessageUpdate()}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    userId: state.user.id,
});

export default connect(mapStateToProps)(TicketsPage);