import React, { Component } from 'react';
import { Popconfirm } from 'antd';

export class IrreversiblePopconfirmWrapper extends Component {
    render() {
        const { children, ...rest } = this.props;

        return (
            <Popconfirm 
            	title="This action is irreversible, are you sure?"
            	okText="Yes"
            	cancelText="No"
            	{...rest}
            >
            	{children}
            </Popconfirm>
        );
    }
}

export default IrreversiblePopconfirmWrapper;