import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Table, Tooltip } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';

import * as organizationController from '../../../../services/api/organization';
import ComfortZoneModal from './components/ComfortZoneModal/ComfortZoneModal';
import './SummaryPage.scss';

export class SummaryPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],
            isLoading: false,
            isComfortZoneModalVisible: false,
            selectedZoneId: undefined,
            selectedZoneName: '',
            refetcher: undefined
        };
    }

    getOrganizationZones() {
        const { selectedOrganization } = this.props;

        this.setState({ isLoading: true });
        organizationController.getOrganization(selectedOrganization)
            .then((res) => res.json())
            .then((json) => {
                this.setState({ dataSource: json.zones, isLoading: false })
            })
            .catch(err => {
                console.log(err);
                this.setState({ isLoading: false });
            })
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedOrganization } = this.props;

        if (selectedOrganization !== prevProps.selectedOrganization)
            this.getOrganizationZones();
    }

    componentDidMount() {
        const { selectedOrganization } = this.props;

        this.setState({
            refetcher: setInterval(() => {
                organizationController.getOrganization(this.props.selectedOrganization)
                    .then((res) => res.json())
                    .then((json) => this.setState({ dataSource: json.zones }))
                    .catch(err => console.log(err))
            }, 15 * 1000)
        });

        if (!_.isUndefined(selectedOrganization))
            this.getOrganizationZones();
    }

    componentWillUnmount() {
        clearInterval(this.state.refetcher);
    }

    render() {
        const { dataSource, isLoading, isComfortZoneModalVisible, selectedZoneId, selectedZoneName } = this.state;
        const columns = [{
            title: 'Zone',
            dataIndex: 'name',
            // sorter: (a, b) => a.name.localeCompare(b.name),
        }, {
            title: 'Curr. Temp (°C)',
            dataIndex: 'currentTemperature',
            align: 'center',
            render: (value) => value ? numeral(value).format('0.[00]') : '--'
            // sorter: (a, b) => a.currentTemperature - b.currentTemperature,
        }, {
            title: <div>Target Setpoint (°C) <Tooltip title="This is showing the pending setpoint request now."><Icon className="info-btn" type="info-circle" /></Tooltip></div>,
            dataIndex: 'pendingZoneRequest',
            align: 'center',
            render: (zoneRequest) => {
                if (zoneRequest) {
                    return numeral(parseFloat(zoneRequest.targetTemp)).format('0.[00]')
                } else {
                    return '--';
                }
            }
            // sorter: (a, b) => a.targetSetpoint - b.targetSetpoint,
        }, {
            title: <div>Last Updated Setpoint (°C) <Tooltip title="This is showing the last updated setpoint within today."><Icon className="info-btn" type="info-circle" /></Tooltip></div>,
            dataIndex: 'successZoneRequest',
            align: 'center',
            // sorter: (a, b) => a.setpointLastUpdated - b.setpointLastUpdated,
            render: (zoneRequest) => {
                if (zoneRequest) {
                    return (
                        <div>
                            <div>{numeral(parseFloat(zoneRequest.targetTemp)).format('0.[00]')}</div>
                            <div className="zone-request-submessage">Last Updated: {moment(zoneRequest.updatedAt).format('HH:mm')}</div>
                        </div>
                    )
                } else {
                    return '--';
                }
            }
        }, {
            title: <div>User Distribution <Tooltip title="This is showing the user distribution(COLD / NORMAL / HOT) in the past hour."><Icon className="info-btn" type="info-circle" /></Tooltip></div>,
            dataIndex: 'userDistribution',
            align: 'center',
            // sorter: (a, b) => a.targetSetpoint - b.targetSetpoint,
            render: (userDistribution, record) => {
                const groupedDistribution = _.groupBy(userDistribution, 'status');

                return (
                    <div className="user-distribution">
                        <span className="cold">{ groupedDistribution['COLD'] ? groupedDistribution['COLD'].length : 0 }</span>
                        <span className="normal">{ groupedDistribution['NORMAL'] ? groupedDistribution['NORMAL'].length : 0 }</span>
                        <span className="hot">{ groupedDistribution['HOT'] ? groupedDistribution['HOT'].length : 0 }</span>
                    </div>
                )
            }
        }, {
            title: 'Active Requests',
            dataIndex: 'requests',
            align: 'center',
            // sorter: (a, b) => a.targetSetpoint - b.targetSetpoint,
        }, {
            title: 'Comfort Zone',
            dataIndex: 'comfortZone',
            align: 'center',
            render: (value, record) => (
                <Icon 
                    className="comfort-zone-btn" 
                    type="dot-chart" 
                    onClick={(e) => this.setState({ selectedZoneId: record.id, selectedZoneName: record.name, isComfortZoneModalVisible: true })} 
                />
            )
        }];

        return (
            <div className="SummaryPage">
                <div className="page-header">
                    <h2>Zones Summary</h2>
                </div>
                <Table
                    loading={isLoading}
                    size="middle"
                    columns={columns}
                    dataSource={dataSource}
                    rowKey="id"
                />
                <ComfortZoneModal
                    selectedZoneId={selectedZoneId}
                    selectedZoneName={selectedZoneName}
                    visible={isComfortZoneModalVisible}
                    onCancel={(e) => this.setState({ isComfortZoneModalVisible: false })}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedOrganization: state.user.selectedOrganization
})

export default connect(mapStateToProps)(SummaryPage);