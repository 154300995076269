import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import './TicketCard.scss';

export class TicketCard extends Component {
    render() {
        const { ticket, onUpdateClick } = this.props;
        const { createdAt, user, zone, ticketProcesses } = ticket;

        return (
            <div className="TicketCard">
            	<div className="ticket-icon"><Icon type="user" /></div>
				<div className="ticket-date">{moment(createdAt).format('DD/MM')}</div>
				<div className="ticket-user">{user && user.name}</div>
				<div className="ticket-zone">{zone && zone.name}</div>
				<div className="ticket-progress-list">
					{
						_.map(ticketProcesses, (step, i) => (
							<div className="ticket-progress" key={i}>
								<div className="progress-time">{moment(step.createdAt).format('DD/MM HH:mm')}</div>
								<div className="progress-message">{step.message}</div>
							</div>
						))
					}
				</div>
				<div className="ticket-btn">
					<Button onClick={(e) => onUpdateClick(e)}>Update</Button>
				</div>
			</div>
        );
    }
}

export default TicketCard;